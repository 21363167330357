<template>
    <div class="container-fluid">
        <section class="content-header p-1" >
            <div class="row mb-2">
                <h3>Configuración baremos</h3>
            </div>
        </section>
        <section class="content">
            <div class="card card-default card-tabs" style="margin:0">
                <div class="card-header p-1 pt-1">
                    <ul class="nav nav-pills">
                        <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
                            <a
                                class="nav-link"
                                :class="{ active: activeTab === tab.id }"
                                @click.prevent="activeTab = tab.id"
                                :href="'#' + tab.id"
                            >
                                {{ tab.name }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="tab-content" style=" margin-top: 15px; padding-bottom: 15px;">
                <component
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :is="tab.component"
                    :lista="tab.lista"
                    :class="['tab-pane', 'container-grid', { active: activeTab === tab.id }]"
                    v-on:cambiolista="obtenerListas"
                ></component>
            </div>
        </section>
    </div>
</template>

<script>
import ActualTab from './actual_baremos.vue';
import FuturosTab from './futuros_baremos.vue';
import AnterioresTab from './anteriores_baremos.vue';
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    components: {
        ActualTab,
        FuturosTab,
        AnterioresTab,
    },
    data() {
        return {
            activeTab: 'actual',
            listaActual: [],
            listaFuturos: [],
            listaAnteriores: [],
            tabs: [
                { id: 'actual', name: 'Actual', component: 'ActualTab', lista: []},
                { id: 'futuros', name: 'Futuros', component: 'FuturosTab', lista: [] },
                { id: 'anteriores', name: 'Anteriores', component: 'AnterioresTab', lista: [] },
            ]
        };
    },
    methods: {
        async obtenerListas() {
            const api = new PwgsApi();
            const lista = await api.get('baremos-pwgs');
            const today = new Date();
            this.listaActual = [];
            this.listaFuturos = [];
            this.listaAnteriores = [];

            for (let [id, datos] of Object.entries(lista.datos)) {
                console.log(id);
                const fechaInicio = new Date(datos.datos.fecha_inicio.replace(/-/g, '/'));
                const fechaFin = new Date(datos.datos.fecha_fin.replace(/-/g, '/'));
                
                
                if (fechaInicio <= today && fechaFin >= today) {
                    this.listaActual.push(datos);
                } else if (fechaInicio > today) {
                    this.listaFuturos.push(datos);
                } else if (fechaFin < today) {
                    this.listaAnteriores.push(datos);
                }
            }
            // Refresh tabs data
            this.tabs[0].lista = this.listaActual;
            this.tabs[1].lista = this.listaFuturos;
            this.tabs[2].lista = this.listaAnteriores;
        },
    },
    mounted() {
        this.obtenerListas();
    }
};
</script>

<style scoped>
.nav-link.active {
    background-color: #007bff;
    color: white;
}

.tab-pane {
    display: none;
}

.tab-pane.active {
    display: block;
}
</style>
